import { Link } from "gatsby";
import React, { CSSProperties } from "react";
import { Trans } from "react-i18next";
import "./layout.css";

const Excerpt = ({
  excerpt,
  highlight,
  excerptEnd,
  scrollId,
  style
}: {
  excerpt: string;
  highlight?: string;
  excerptEnd?: string;
  scrollId?: string;
  style?: CSSProperties;
}) => {
  return (
    <div className="container-inner" style={style}>
      <div className="image-excerpt">
        <p style={{ display: `inline` }}>
          <Trans i18nKey={excerpt} />{" "}
        </p>
        <p style={{ display: `inline`, color: `deepskyblue` }}>
          <Trans i18nKey={highlight} />
        </p>
        <p style={{ display: `inline` }}>
          {" "}
          <Trans i18nKey={excerptEnd} />
        </p>
      </div>
    </div>
  );
};

export default Excerpt;
