import { Link } from "gatsby";
import React from "react";
import { Trans } from "react-i18next";
import { Sidebar } from "rsuite";
import "./layout.css";

const LayoutFooter = () => {
  return (
    <div className="layout-footer">
      <a href={"#page"} className="layout-footer-arrow-outer">
        <img
          src="https://www.surya-digital.com/wp-content/uploads/2017/10/arrow-bottom.png"
          alt="Arrow top"
          className="arrow-scroll"
        />
      </a>
      <div className="layout-footer-div">
        <Sidebar className="layout-footer-logo" />
        <div>
          <a
            className="legal-notice-p"
            href="https://www.surya-digital.com/wp-content/uploads/2018/06/ml.html"
          >
            <Trans i18nKey="Legal Notice" />
          </a>
          <div className="layout-footer-links">
            <div className="layout-footer-link">
              <Link to="/society">
                <Trans i18nKey="SOCIETY" />
              </Link>
            </div>
            <div className="layout-footer-link">
              <Link to="/services">
                {" "}
                <Trans i18nKey="SERVICES" />
              </Link>
            </div>
            <div className="layout-footer-link">
              <Link to="/references">
                {" "}
                <Trans i18nKey="REFERENCES" />
              </Link>
            </div>
          </div>
          <div
            style={{
              marginRight: `20px`
            }}
          >
            <img
              src="https://www.surya-digital.com/wp-content/themes/surya-digital/images/surya_f_logo.png"
              alt="Surya_f_logo"
              style={{
                maxWidth: `100%`
              }}
            />
            <a href={"#page"} className="layout-footer-arrow-inner">
              <img
                src="https://www.surya-digital.com/wp-content/uploads/2017/10/arrow-bottom.png"
                alt="Arrow top"
                className="arrow-scroll"
                style={{ marginTop: `10px`, verticalAlign: `top` }}
              />
            </a>
            <p>
              <span>World Trade Center Lyon - Oxygen Tower</span>
              <span
                style={{
                  display: `block`
                }}
              >
                10-12 Boulevard Vivier-Merle
              </span>
              <span>69393 Lyon Cedex 03</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutFooter;
