import { Link } from "gatsby";
import React from "react";
import { Container } from "rsuite";

const LayoutNav = () => {
  return (
    <Container
      style={{
        height: `100%`,
        width: `100%`
      }}
    >
      <ul className="layout-nav-ul">
        <li className="layout-nav-li">
          <Link className="layout-nav-link" to="/society">
            Society
          </Link>
        </li>
        <li className="layout-nav-li">
          <Link className="layout-nav-link" to="/services">
            Services
          </Link>
        </li>
        <li className="layout-nav-li" style={{ borderBottom: `none` }}>
          <Link className="layout-nav-link" to="/references">
            References
          </Link>
        </li>
      </ul>
    </Container>
  );
};

export default LayoutNav;
