import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import Cache from "i18next-localstorage-cache";
import LanguageDetector from "i18next-browser-languagedetector";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "accelerate your growth": "accelerate your growth",
      "2 processes": "2 processes",
      SERVICES: "SERVICES",
      SOCIETY: "SOCIETY",
      TESTING: "TESTING",
      APPLICATIONS: "APPLICATIONS",
      "DATA SCIENTISTS": "DATA SCIENTISTS",
      "MICROSOFT DYNAMICS AX": "MICROSOFT DYNAMICS AX",
      REFERENCES: "REFERENCES",
      "accelerate your growth": "accelerate your growth",
      "SEE OUR SERVICES >>": "SEE OUR SERVICES >>",
      "We establish together, centers of competence, dedicated to the":
        "We establish together, centers of competence, dedicated to the",
      "optimization of your Information Systems":
        "optimization of your Information Systems",
      Concentrate: "Concentrate",
      on: "on",
      "your know how": "your know how",
      "Unobstructed from these tasks,": "Unobstructed from these tasks,",
      "your designers can focus on innovation.":
        "your designers can focus on innovation.",
      "18 years of experience": "18 years of experience",
      "at your service": "at your service",
      "Established on 4 continents and 20 countries,":
        "Established on 4 continents and 20 countries,",
      "we favor the long-term partnership with our customers, to promote your brand image optimally.":
        "we favor the long-term partnership with our customers, to promote your brand image optimally.",
      "Adapt your Information Technologies (IT) to the":
        "Adapt your Information Technologies (IT) to the",
      "vision of your brand.": "vision of your brand.",
      "Legal Notice": "Legal Notice",
      "Offer an": "Offer an",
      "optimal digital experience": "optimal digital experience",
      "to your customers": "to your customers",
      "Your site or application is already existing.":
        "Your site or application is already existing.",
      "We perform comprehensive, technical and ergonomic testing of your digital products. We analyze and identify dysfunctions.Together we find the right solutions.":
        "We perform comprehensive, technical and ergonomic testing of your digital products. We analyze and identify dysfunctions.Together we find the right solutions.",
      "Your site or application is in the development phase":
        "Your site or application is in the development phase",
      "Our experts fix the most common bugs, without touching the features.":
        "Our experts fix the most common bugs, without touching the features.",
      "We guarantee a Quality Assurance (QA) compliant.":
        "We guarantee a Quality Assurance (QA) compliant.",
      "We offer direct integration into your production process. The tests are carried out throughout the design. Our engineers solve anomalies as soon as they are diagnosed.":
        "We offer direct integration into your production process. The tests are carried out throughout the design. Our engineers solve anomalies as soon as they are diagnosed.",
      "From design to production, our developers and technicians produce":
        "From design to production, our developers and technicians produce",
      "software guaranteed to Quality Assurance (QA) standards.":
        "software guaranteed to Quality Assurance (QA) standards.",
      The: "The",
      "Bangalore University of Science and Technology":
        "Bangalore University of Science and Technology",
      "is one of the most advanced centers in the processing and analysis of big data.":
        "is one of the most advanced centers in the processing and analysis of big data.",
      "The best PHDs in the field are trained in this institution, and the biggest players in big data are there.":
        "The best PHDs in the field are trained in this institution, and the biggest players in big data are there.",
      "In partnership with the University of Bangalore, we set up centers of expertise specialized in your sector.":
        "In partnership with the University of Bangalore, we set up centers of expertise specialized in your sector.",
      "Our doctoral students in mathematics, statistics, or specialized in other technologies, formulate advanced algorithms. By precisely defining your search, our algorithms make it possible to extract the data generating your growth.":
        "Our doctoral students in mathematics, statistics, or specialized in other technologies, formulate advanced algorithms. By precisely defining your search, our algorithms make it possible to extract the data generating your growth.",
      "Dynamics AX Competence Center": "Dynamics AX Competence Center",
      "Take advantage of our 150 engineers to optimize the use of":
        "Take advantage of our 150 engineers to optimize the use of",
      "Specific development adapted to the needs of your company.":
        "Specific development adapted to the needs of your company.",
      "Consulting-parameter setting to make the most of the capabilities of your ERP.":
        "Consulting-parameter setting to make the most of the capabilities of your ERP.",
      "From data migration to your new platform.":
        "From data migration to your new platform.",
      "Data collection and analysis to help you with your decision strategy.":
        "Data collection and analysis to help you with your decision strategy.",
      "Well used, this package reduces the risk of error and becomes a major asset of your success.":
        "Well used, this package reduces the risk of error and becomes a major asset of your success.",
      "We propose :": "We propose :",
      "Benefit from the experience of the greatest data scientists.":
        "Benefit from the experience of the greatest data scientists.",
      "Give your customers the best use of your digital tools.":
        "Give your customers the best use of your digital tools.",
      "Coordinate the IT management devices of your business.":
        "Coordinate the IT management devices of your business.",
      "And increase your value creation.": "And increase your value creation.",
      "Speed ​​up your internal process.": "Speed ​​up your internal process.",
      "We directly integrate your release development teams.":
        "We directly integrate your release development teams.",
      "Our proven test methodologies, list the malfunctions of your sites and applications, to establish a quick and adapted response.":
        "Our proven test methodologies, list the malfunctions of your sites and applications, to establish a quick and adapted response.",
      Choose: "Choose",
      "the experience": "the experience",
      "to make your Apps.": "to make your Apps.",
      "Get relevant data and": "Get relevant data and",
      "increase your performance.": "increase your performance.",
      Boost: "Boost",
      "your business performance.": "your business performance.",
      "Testing Activity": "Testing Activity",
      "IOS & Android Apps Development": "IOS & Android Apps Development",
      "Data Scientists": "Data Scientists",
      //
      "accompanies the success of its customers":
        "accompanies the success of its customers",
      "We build lasting relationships with each of our clients.":
        "We build lasting relationships with each of our clients.",
      Flexibility: "Flexibility",
      Reactivity: "Reactivity",
      Adaptability: "Adaptability",
      Transparency: "Transparency",
      "Our long-term partnership policy contributes to your growth in various business sectors.":
        "Our long-term partnership policy contributes to your growth in various business sectors.",
      "Major French Luxury Brands": "Major French Luxury Brands",
      "International Banks": "International Banks",
      "Communication Agencies": "Communication Agencies",
      startups: "startups",
      multinationals: "multinationals",
      "Contact us to know our references and deepen our areas of expertise.":
        "Contact us to know our references and deepen our areas of expertise."
    }
  },
  "en-US": {
    translation: {
      "accelerate your growth": "accelerate your growth",
      "2 processes": "2 processes",
      SERVICES: "SERVICES",
      SOCIETY: "SOCIETY",
      TESTING: "TESTING",
      APPLICATIONS: "APPLICATIONS",
      "DATA SCIENTISTS": "DATA SCIENTISTS",
      "MICROSOFT DYNAMICS AX": "MICROSOFT DYNAMICS AX",
      REFERENCES: "REFERENCES",
      "accelerate your growth": "accelerate your growth",
      "SEE OUR SERVICES >>": "SEE OUR SERVICES >>",
      "We establish together, centers of competence, dedicated to the":
        "We establish together, centers of competence, dedicated to the",
      "optimization of your Information Systems":
        "optimization of your Information Systems",
      Concentrate: "Concentrate",
      on: "on",
      "your know how": "your know how",
      "SEE OUR REFERENCES >>": "SEE OUR REFERENCES >>",
      "Unobstructed from these tasks,": "Unobstructed from these tasks,",
      "your designers can focus on innovation.":
        "your designers can focus on innovation.",
      "18 years of experience": "18 years of experience",
      "at your service": "at your service",
      "Established on 4 continents and 20 countries,":
        "Established on 4 continents and 20 countries,",
      "we favor the long-term partnership with our customers, to promote your brand image optimally.":
        "we favor the long-term partnership with our customers, to promote your brand image optimally.",
      "Adapt your Information Technologies (IT) to the":
        "Adapt your Information Technologies (IT) to the",
      "vision of your brand.": "vision of your brand.",
      "Legal Notice": "Legal Notice",
      "Offer an": "Offer an",
      "optimal digital experience": "optimal digital experience",
      "to your customers": "to your customers",
      "Your site or application is already existing.":
        "Your site or application is already existing.",
      "We perform comprehensive, technical and ergonomic testing of your digital products. We analyze and identify dysfunctions.Together we find the right solutions.":
        "We perform comprehensive, technical and ergonomic testing of your digital products. We analyze and identify dysfunctions.Together we find the right solutions.",
      "Your site or application is in the development phase":
        "Your site or application is in the development phase",
      "Our experts fix the most common bugs, without touching the features.":
        "Our experts fix the most common bugs, without touching the features.",
      "We guarantee a Quality Assurance (QA) compliant.":
        "We guarantee a Quality Assurance (QA) compliant.",
      "We offer direct integration into your production process. The tests are carried out throughout the design. Our engineers solve anomalies as soon as they are diagnosed.":
        "We offer direct integration into your production process. The tests are carried out throughout the design. Our engineers solve anomalies as soon as they are diagnosed.",
      "From design to production, our developers and technicians produce":
        "From design to production, our developers and technicians produce",
      "software guaranteed to Quality Assurance (QA) standards.":
        "software guaranteed to Quality Assurance (QA) standards.",
      The: "The",
      "Bangalore University of Science and Technology":
        "Bangalore University of Science and Technology",
      "is one of the most advanced centers in the processing and analysis of big data.":
        "is one of the most advanced centers in the processing and analysis of big data.",
      "The best PHDs in the field are trained in this institution, and the biggest players in big data are there.":
        "The best PHDs in the field are trained in this institution, and the biggest players in big data are there.",
      "In partnership with the University of Bangalore, we set up centers of expertise specialized in your sector.":
        "In partnership with the University of Bangalore, we set up centers of expertise specialized in your sector.",
      "Our doctoral students in mathematics, statistics, or specialized in other technologies, formulate advanced algorithms. By precisely defining your search, our algorithms make it possible to extract the data generating your growth.":
        "Our doctoral students in mathematics, statistics, or specialized in other technologies, formulate advanced algorithms. By precisely defining your search, our algorithms make it possible to extract the data generating your growth.",
      "Dynamics AX Competence Center": "Dynamics AX Competence Center",
      "Take advantage of our 150 engineers to optimize the use of":
        "Take advantage of our 150 engineers to optimize the use of",
      "Specific development adapted to the needs of your company.":
        "Specific development adapted to the needs of your company.",
      "Consulting-parameter setting to make the most of the capabilities of your ERP.":
        "Consulting-parameter setting to make the most of the capabilities of your ERP.",
      "From data migration to your new platform.":
        "From data migration to your new platform.",
      "Data collection and analysis to help you with your decision strategy.":
        "Data collection and analysis to help you with your decision strategy.",
      "Well used, this package reduces the risk of error and becomes a major asset of your success.":
        "Well used, this package reduces the risk of error and becomes a major asset of your success.",
      "We propose :": "We propose :",
      "Benefit from the experience of the greatest data scientists.":
        "Benefit from the experience of the greatest data scientists.",
      "And increase your value creation.": "And increase your value creation.",
      "Give your customers the best use of your digital tools.":
        "Give your customers the best use of your digital tools.",
      "Coordinate the IT management devices of your business.":
        "Coordinate the IT management devices of your business.",
      "Speed ​​up your internal process.": "Speed ​​up your internal process.",
      "We directly integrate your release development teams.":
        "We directly integrate your release development teams.",
      "Our proven test methodologies, list the malfunctions of your sites and applications, to establish a quick and adapted response.":
        "Our proven test methodologies, list the malfunctions of your sites and applications, to establish a quick and adapted response.",
      Choose: "Choose",
      "the experience": "the experience",
      "to make your Apps.": "to make your Apps.",
      "Get relevant data and": "Get relevant data and",
      "increase your performance.": "increase your performance.",
      Boost: "Boost",
      "your business performance.": "your business performance.",
      "Testing Activity": "Testing Activity",
      "IOS & Android Apps Development": "IOS & Android Apps Development",
      "Data Scientists": "Data Scientists",
      "accompanies the success of its customers":
        "accompanies the success of its customers",
      "We build lasting relationships with each of our clients.":
        "We build lasting relationships with each of our clients.",
      Flexibility: "Flexibility",
      Reactivity: "Reactivity",
      Adaptability: "Adaptability",
      Transparency: "Transparency",
      "Our long-term partnership policy contributes to your growth in various business sectors.":
        "Our long-term partnership policy contributes to your growth in various business sectors.",
      "Major French Luxury Brands": "Major French Luxury Brands",
      "International Banks": "International Banks",
      "Communication Agencies": "Communication Agencies",
      startups: "startups",
      multinationals: "multinationals",
      "Contact us to know our references and deepen our areas of expertise.":
        "Contact us to know our references and deepen our areas of expertise."
    }
  },
  fr: {
    translation: {
      "accelerate your growth": "accélère votre croissance",
      "2 processes": "2 Procédés",
      SERVICES: "SERVICES",
      SOCIETY: "SOCIÉTÉ",
      TESTING: "TESTING",
      APPLICATIONS: "APPLICATIONS",
      "DATA SCIENTISTS": "DATA SCIENTISTS",
      "MICROSOFT DYNAMICS AX": "MICROSOFT DYNAMICS AX",
      REFERENCES: "RÉFÉRENCES",
      "SEE OUR SERVICES >>": "VOIR NOS SERVICES >>",
      "We establish together, centers of competence, dedicated to the":
        "Nous instaurons ensemble, des centres de compétences,dédiés à l' ",
      "optimization of your Information Systems":
        "optimisation de vos Systèmes d'Information",
      "(IS).": "(IS).",
      Concentrate: "Concentrez-vous",
      on: "sur",
      "your know how": "votre savoir-faire",
      "SEE OUR REFERENCES >>": "VOIR NOS RÉFÉRENCES >>",
      "Unobstructed from these tasks,": "Dégagés de ces tâches,",
      "your designers can focus on innovation.":
        "vos concepteurs peuvent se consacrer à l'innovation.",
      "18 years of experience": "18 années d'expérience",
      "at your service": "à votre service",
      "Established on 4 continents and 20 countries,":
        "Implantés sur 4 continents et 20 pays,",
      "we favor the long-term partnership with our customers, to promote your brand image optimally.":
        "nous privilégions le partenariat à long terme avec nos clients, pour promouvoir de manière optimum votre image de marque.", //
      "Adapt your Information Technologies (IT) to the":
        "Adaptez vos Technologies de l'Information (IT) à la",
      "vision of your brand.": "vision de votre marque.",
      "Legal Notice": "Mentions légales",
      "Offer an": "Proposez une",
      "optimal digital experience": "expérience digitale optimale",
      "to your customers": "à vos clients",
      "Your site or application is already existing.":
        "Votre site ou application est déjà existant.",
      "We perform comprehensive, technical and ergonomic testing of your digital products. We analyze and identify dysfunctions.Together we find the right solutions.":
        "Nous réalisons des essais complets, techniques et ergonomiques de vos produits numériques. Nous analysons et identifions les dysfonctionnements. Nous trouvons ensemble les solutions adaptées.",
      "Your site or application is in the development phase":
        "Votre site ou application est en phase de développement",
      "We offer direct integration into your production process. The tests are carried out throughout the design. Our engineers solve anomalies as soon as they are diagnosed.":
        "Nous proposons une intégration directe à votre processus de production. Les tests sont réalisés tout au long de la conception. Nos ingénieurs résolvent les anomalies dès diagnostic.",
      "Our experts fix the most common bugs, without touching the features.":
        "Nos experts corrigent les bugs les plus courants, sans toucher aux fonctionnalités.",
      "We guarantee a Quality Assurance (QA) compliant.":
        "Nous garantissons une Assurance Qualité (QA) conforme.",
      "From design to production, our developers and technicians produce":
        "De la conception à la production, nos développeurs et techniciens, produisent",
      "software guaranteed to Quality Assurance (QA) standards.":
        "des logiciels garantis aux normes d’Assurance Qualité (QA).",
      The: "L’",
      "Bangalore University of Science and Technology":
        "Université de Science et Technologie de Bangalore",
      "is one of the most advanced centers in the processing and analysis of big data.":
        "st l’un des pôles les plus avancés dans le traitement et l’analyse des mégadonnées.",
      "The best PHDs in the field are trained in this institution, and the biggest players in big data are there.":
        "Les meilleurs PHD dans le domaine sont formés dans de cette institution, et les plus grands acteurs du big data s’y retrouvent.",
      "In partnership with the University of Bangalore, we set up centers of expertise specialized in your sector.":
        "En partenariat avec l’Université de Bangalore, nous mettons en place des pôles de compétences spécialisés dans votre secteur.",
      "Our doctoral students in mathematics, statistics, or specialized in other technologies, formulate advanced algorithms. By precisely defining your search, our algorithms make it possible to extract the data generating your growth.":
        "Nos doctorants en mathématiques, en statistiques, ou spécialisés dans d’autres technologies, formulent des algorithmes avancées. En définissant avec précision votre recherche, nos algorithmes permettent d’extraire les données génératrices de votre croissance.",
      "Dynamics AX Competence Center": "Centre de compétences de Dynamics AX",
      "Take advantage of our 150 engineers to optimize the use of":
        "Profitez de nos 150 ingénieurs pour optimiser l’utilisation de",
      "Microsoft Dynamics AX": "Microsoft Dynamics AX",
      "Specific development adapted to the needs of your company.":
        "Du développement spécifique adapté aux besoins de votre entreprise.",
      "Consulting-parameter setting to make the most of the capabilities of your ERP.":
        "Du consulting-paramétrage pour exploiter au mieux les capacités de votre ERP.",
      "From data migration to your new platform.":
        "De la migration de données vers votre nouvelle plateforme.",
      "Data collection and analysis to help you with your decision strategy.":
        "De la collecte et analyse de données pour vous aider dans votre stratégie de décisions.",
      "Well used, this package reduces the risk of error and becomes a major asset of your success.":
        "Bien utilisé, ce progiciel réduit conséquemment le risque d’erreur et devient un atout majeur de votre réussite.",
      "We propose :": "Nous proposons :",
      "Benefit from the experience of the greatest data scientists.":
        "Profitez de l’expérience des plus grands data scientists.",
      "Give your customers the best use of your digital tools.":
        "Offrez à vos clients une utilisation optimale de vos outils digitaux.",
      "Coordinate the IT management devices of your business.":
        "Coordonnez les dispositifs informatiques de gestion de votre entreprise.",
      "And increase your value creation.":
        "Et augmentez votre création de valeur.",
      "Speed ​​up your internal process.": "Accélérez votre processus interne.",
      "We directly integrate your release development teams.":
        "Nous intégrons directement vos équipes de développement de releases.",
      "Our proven test methodologies, list the malfunctions of your sites and applications, to establish a quick and adapted response.":
        "Nos méthodologies de tests éprouvées, listent les dysfonctionnements de vos sites et applications, pour établir une réponse rapide et adaptée.",
      Choose: "Choisissez",
      "the experience": "l'expérience",
      "to make your Apps.": "pour réaliser vos Apps.",
      "Get relevant data and": "Récupérez des données pertinentes et",
      "increase your performance.": "augmentez votre rendement.",
      Boost: "Renforcez les",
      "your business performance.": "performances de votre entreprise.",
      "Testing Activity": "Activité de testing",
      "IOS & Android Apps Development": "Développement d’Apps IOS & Android",
      "Data Scientists": "Data Scientists",
      //
      "accompanies the success of its customers":
        "accompagne la réussite de ses clients",
      "We build lasting relationships with each of our clients.":
        "Nous construisons des relations pérennes avec chacun de nos clients.",
      Flexibility: "Flexibilité",
      Reactivity: "Réactivité",
      Adaptability: "Adaptabilité",
      Transparency: "Transparence",
      "Our long-term partnership policy contributes to your growth in various business sectors.":
        "Notre politique de partenariat à long terme participe à votre croissance dans différents secteurs d’activité.",
      "Major French Luxury Brands": "Grandes marques de Luxe Françaises",
      "International Banks": "Banques Internationales",
      "Communication Agencies": "Agences de communication",
      startups: "Startups",
      multinationals: "Multinationales",
      "Contact us to know our references and deepen our areas of expertise.":
        "Prenez contact avec nous pour connaitre nos références et approfondir nos domaines de compétences."
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
