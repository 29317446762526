import React, { CSSProperties, ReactNode } from "react";
import { Trans } from "react-i18next";
import { Parallax } from "react-scroll-parallax";
import "./layout.css";

const Container = ({
  containerTitle,
  children,
  style
}: {
  containerTitle?: string;
  children: ReactNode;
  style?: CSSProperties;
}) => {
  return (
    <Parallax
      tagOuter="figure"
      styleOuter={{
        height: `fit-content`,
        marginLeft: `0px`,
        marginRight: `0px`
      }}
    >
      <div className="container" style={style}>
        <h1>
          <Trans i18nKey={containerTitle} />
        </h1>
        <div style={{ height: `fit-content`, paddingBottom: `2rem` }}>
          {children}
        </div>
      </div>
    </Parallax>
  );
};

export default Container;
