import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { GlobalsNumber } from "csstype";
import { Link } from "gatsby";
import i18n from "i18next";
import React, { ReactNode } from "react";
import Helmet from "react-helmet";
import { Trans, withTranslation } from "react-i18next";
import { ParallaxProvider } from "react-scroll-parallax";
import { CSSTransition } from "react-transition-group";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import "../../i18n.js";
import "./layout.css";
import LayoutFooter from "./layoutFooter";
import LayoutNarrow from "./layoutNarrow";

export interface LayoutProps {
  children: ReactNode;
}

export interface LayoutState {
  transition: boolean;
  render: boolean;
  dropdownServiceOpen: boolean;
  dropdownLangOpen: boolean;
}

class Layout extends React.Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props);
    this.state = {
      dropdownLangOpen: false,
      dropdownServiceOpen: false,
      render: false,
      transition: false
    };
  }

  public toggleLang = () => {
    this.setState(prevState => ({
      dropdownLangOpen: !prevState.dropdownLangOpen
    }));
  };

  public toggleServiceDropdown = () => {
    this.setState(prevState => ({
      dropdownServiceOpen: !prevState.dropdownServiceOpen
    }));
  };

  public onMouseEnter = (dropdown: string) => {
    this.setState({ [dropdown]: true });
  };

  public onMouseLeave = (dropdown: string) => {
    this.setState({ [dropdown]: false });
  };

  public render() {
    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="#"]');
    }
    return (
      <ParallaxProvider>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Montserrat:Light"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css"
          />
        </Helmet>
        <div>
          <header className="layout-header-backdrop" />
          <header className={"layout-header"}>
            <Link to="/" className="navbar-logo-left">
              <img
                src="https://www.surya-digital.com/wp-content/themes/surya-digital/images/logo.png"
                style={{ opacity: `0.9` as GlobalsNumber }}
                alt="Surya Digital Logo"
              />
            </Link>

            <Nav
              style={{ padding: `0` }}
              className={!this.state.transition ? "navbar" : "navbar-hide"}
            >
              <NavItem className="navbar-item">
                {" "}
                <NavLink
                  href="/society/"
                  style={{
                    height: `100%`,
                    paddingTop: `0.7rem`,
                    width: `100%`
                  }}
                >
                  <Trans i18nKey="SOCIETY" />
                </NavLink>
              </NavItem>

              <div className="navbar-item">
                <Dropdown
                  isOpen={this.state.dropdownServiceOpen}
                  toggle={this.toggleServiceDropdown}
                  onMouseOver={() => this.onMouseEnter("dropdownServiceOpen")}
                  onMouseLeave={() => this.onMouseLeave("dropdownServiceOpen")}
                >
                  <DropdownToggle
                    variant="secondary"
                    style={{ background: `transparent`, border: `none` }}
                    id="dropdown-basic"
                    className="navbar-item"
                  >
                    <Trans i18nKey="SERVICES" />
                  </DropdownToggle>

                  <DropdownMenu
                    style={{
                      background: `black`,
                      border: `1px solid deepskyblue`,
                      color: `white`,
                      margin: `0`,
                      padding: `0`
                    }}
                  >
                    <DropdownItem href="/services/" className="dropdownItem">
                      <Trans i18nKey="TESTING" />
                    </DropdownItem>
                    <DropdownItem href="/services/" className="dropdownItem">
                      <Trans i18nKey="APPLICATIONS" />
                    </DropdownItem>
                    <DropdownItem href="/services/" className="dropdownItem">
                      <Trans i18nKey="DATA SCIENTISTS" />
                    </DropdownItem>
                    <DropdownItem
                      href="/services/"
                      className="dropdownItem"
                      style={{ borderBottom: `none` }}
                    >
                      <Trans i18nKey="MICROSOFT DYNAMICS AX" />
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <NavItem className="navbar-item">
                <NavLink
                  href="/references/"
                  style={{
                    height: `100%`,
                    paddingTop: `0.7rem`,
                    width: `100%`
                  }}
                >
                  <Trans i18nKey="REFERENCES" />
                </NavLink>
              </NavItem>
              <div className="navbar-item">
                <Dropdown
                  isOpen={this.state.dropdownLangOpen}
                  toggle={this.toggleLang}
                  onMouseOver={() => this.onMouseEnter("dropdownLangOpen")}
                  onMouseLeave={() => this.onMouseLeave("dropdownLangOpen")}
                >
                  <DropdownToggle
                    variant="secondary"
                    style={{ background: `transparent`, border: `none` }}
                    id="dropdown-basic"
                    className="navbar-item"
                  >
                    {i18n.language.toLocaleUpperCase()}
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      background: `black`,
                      border: `1px solid deepskyblue`,
                      color: `white`,
                      margin: `0`,
                      padding: `0px`
                    }}
                  >
                    <DropdownItem
                      className="dropdownItem"
                      eventKey="en-US"
                      onClick={() => {
                        i18n.changeLanguage("en-US");
                      }}
                    >
                      EN
                    </DropdownItem>
                    <DropdownItem
                      className="dropdownItem"
                      eventKey="fr"
                      onClick={() => {
                        i18n.changeLanguage("fr");
                      }}
                      style={{ borderBottom: `none` }}
                    >
                      FR
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Nav>
            <button
              className={
                this.state.transition
                  ? "navbar-sidebar-button"
                  : "navbar-sidebar-button-hide"
              }
              onClick={() => {
                this.handleClick();
              }}
            >
              {!this.state.transition && (
                <img
                  src="https://www.surya-digital.com/wp-content/themes/surya-digital/images/menu_icon.png"
                  alt="hamburger open"
                  style={{
                    height: `40px`,
                    width: `40px`
                  }}
                />
              )}
              {this.state.transition && (
                <div className="layout-narrow-open-hamburger-logo">X</div>
              )}
            </button>
          </header>
          <main
            id="page"
            style={this.state.render ? { position: `fixed` } : null}
          >
            {this.props.children}
            {!this.state.transition && <LayoutFooter />}
          </main>
          <CSSTransition
            in={this.state.transition}
            classNames="nav-animation"
            appear={true}
            timeout={500}
          >
            <div className="layout-narrow">
              <LayoutNarrow />
            </div>
          </CSSTransition>
        </div>
      </ParallaxProvider>
    );
  }

  public handleClick = () => {
    this.setState({
      transition: !this.state.transition
    });
    if (this.state.render === false) {
      this.setState({ render: true });
    } else {
      setTimeout(() => {
        this.setState({ render: !this.state.render });
      }, 350);
    }
  };
}

export default withTranslation()(Layout);
